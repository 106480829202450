import { useMemo } from "react";
import { GenericGrid } from "./GenericGrid";
import {
	type GenericGridProps,
	type JobActions,
	type TypedGridColumnProps,
	toCurrency,
} from "./helpers";
import { JobStatusCell, toCell } from "./helpersReact";

type Job = {
	actions: JobActions;
	status: number;
	statusName: string;
	id: number;
	uniqueId: string;
	type: number;
	typeName: string;
	poNumber: string;
	customerName: string;
	startLocationName: string;
	startDate: Date;
	endLocationName: string;
	endDate: Date;
	price: number | undefined;
	cost: number | undefined;
	currencyCode: string | undefined;
	driverName: string;
	assignedToName: string;
	startDateString: string;
	endDateString: string;
	subcontractorName: string;
	isNew: boolean;
};

const defaultColumns: TypedGridColumnProps<Job>[] = [
	{
		field: "statusName",
		title: "Status",
		cell: ({ dataItem }) => (
			<JobStatusCell
				status={dataItem.status}
				name={dataItem.statusName}
				isNew={dataItem.isNew}
				title={`${dataItem.statusName}${dataItem.isNew ? " (Need Review)" : ""}`}
			/>
		),
	},
	{ field: "uniqueId", title: "ID" },
	{ field: "typeName", title: "Type" },
	{ field: "poNumber", title: "PO Number" },
	{ field: "customerName", title: "Customer" },
	{ field: "startLocationName", title: "Start Location" },
	{ field: "endLocationName", title: "End Location" },
	{
		field: "startDate",
		title: "Start Date",
		cell: ({ dataItem }) => toCell(dataItem.startDateString),
	},
	{
		field: "endDate",
		title: "End Date",
		cell: ({ dataItem }) => toCell(dataItem.endDateString),
	},
	{
		field: "price",
		title: "Price",
		filter: "numeric",
		cell: ({ dataItem }) =>
			toCell(
				dataItem.price ? toCurrency(dataItem.price, dataItem.currencyCode) : "",
			),
	},
	{
		field: "cost",
		title: "Cost",
		filter: "numeric",
		cell: ({ dataItem }) =>
			toCell(
				dataItem.cost ? toCurrency(dataItem.cost, dataItem.currencyCode) : "",
			),
	},
	{ field: "currencyCode", title: "Currency", hidden: true },
	{ field: "driverName", title: "Driver" },
	{ field: "subcontractorName", title: "Subcontractor" },
	{ field: "assignedToName", title: "Assigned To" },
];

export const JobsGrid = ({
	extraColumns = [],
	...rest
}: GenericGridProps<Job>) => {
	const columns = useMemo(
		() => [...defaultColumns, ...extraColumns],
		[extraColumns],
	);
	return <GenericGrid defaultColumns={columns} name="Jobs" {...rest} />;
};
